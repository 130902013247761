:root {
    --button-primary-color: #fff;
}

html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat', sans-serif;
    background-color: #0F161E;
}

.body-wrapper {
    overflow-x: hidden;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

h1 {
    font-weight: 400;
font-size: 20px;
line-height: 25px;
text-align: center;
color: #FFFFFF;
}

h1 span {
    display: block;
    font-weight: 600;
font-size: 50px;
line-height: 60px;
}

h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 51px;
    padding-bottom: 40px;
    padding-top: 90px;
    color: #fff;
}

.site-logo {
    font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 38px;
color: #FFFFFF;
position: relative;
}

.site-logo::before {
    position: absolute;
    content: "";
    background-image: url(../image/logo.svg);
    top: 0;
    right: 25px;
    width: 20px;
    height: 7px;
    background-repeat: no-repeat;
    background-size: contain;
}



/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
    margin-bottom: 25px;
  }
  .banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
  }
  .banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
  }
  .banner .banner-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
  }
  .banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
    padding: 0;
  }
  .banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
  }
  .banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }
  .banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
  }
  @media only screen and (max-width: 1090px) {
    .banner .banner-title{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
        text-align: left!important;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
  }
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .banner-title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center!important;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }

.nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
}

.nav {
    position: relative;
    z-index: 2;
}

.nav::before {
    position: absolute;
    content: "";
    background: rgba(91, 137, 255, 0.15);
    filter: blur(200px);
    width: 100%;
    height: 500px;
    top: 0;
    z-index: -1;
}

.nav ul {
    display: flex;
    gap: 40px;
    align-items: center;
}

.nav ul li a {
    font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #7E8997;
position: relative;
}

.nav ul li a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #7E8997;
    bottom: -2px;
    opacity: 0;
    transition: all 0.3s;
}

.nav ul li a:hover::before {
    opacity: 1;
}

.cta-btn span, .cta-btn a{
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    background-color: #F43D58;
    border:0;
    padding: 10px 32px;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
}

.cta-btn span:hover, .cta-btn a:hover {
    background-color: #FE5A72;
}

.site-header-inner {
    position: relative;
    z-index: 3;
    padding-top: 55px;
}

.site-header-inner::before {
    position: absolute;
    content: "";
    background-image: url(../image/hero-lines-bg.png);
    width: 1500px;
    height: 550px;
    top: 208px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
}

.site-header-inner::after  {
    content: "";
    position: absolute;
width: 100%;
height: 500px;
background: #F43D58;
opacity: 0.13;
filter: blur(300px);
bottom: -168px;
z-index: -1;
}

.hero-img {
    height: 550px;
    position: relative;
}

.hero-img::before {
    position: absolute;
    content: "";
    background-image: url(../image/football-baseball.png);
    width: 662px;
    height: 518px;
    background-repeat: no-repeat;
    background-size: contain;
    right: 0;
    bottom: -50px;
}

.hero-img::after {
    position: absolute;
    content: "";
    background-image: url(../image/rugby-hero.webp);
    width: 781px;
    height: 600px;
    background-repeat: no-repeat;
    background-size: contain;
    left: 123px;
    bottom: -101px;
}

.site-header {
    position: relative;
}

.site-header::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 104px;
    background: #000000;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
    bottom: 0;
    z-index: 4;
    left: 0;
}

.site-header .cta-btn:hover span::after{
    position: absolute;
    content: "Coming soon!";
    width: 100%;
    height: 40px;
    background: #98263c;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -55px;
}

.site-header .cta-btn:hover span::before {
    border-color: transparent transparent #98263c;
    border-style: solid;
    border-width: 0 10px 8px;
    content: "";
    position: absolute;
    height: 0;
    left: calc(50% - 5px);
    top: 48px;
    transition: all .15s;
    width: 0;
}


/* MAIN  */

p {
    color: #fff;
    font-weight: 300;
font-size: 19px;
line-height: 38px;
}

p:not(:last-child) {
    padding-bottom: 25px;
}

.description {
    padding: 70px 0;
    text-align: center;
    border-bottom: 1px solid #1F3043;
}

.main {
    position: relative;
    z-index: 5;
    background-color: #0F161E;;
}

.types {
    display: flex;
    align-items: center;
    width: 66%;
    margin: 0 auto;
    justify-content: space-between;
}

.box {
    border: 1px solid #3A4148;
    width: 220px;
    padding-top: 150px;
    padding-bottom: 10px;
    position: relative;
    background-color: transparent;
    transition: all 0.15s ease-in-out;
}
.box:hover{
    background-color: #F43D58;
    color: #ffffff;
}
.american::before {
    position: absolute;
    content: "";
    background-image: url(../image/american.png);
    width: 75px;
    height: 75px;
    background-repeat: no-repeat;
    background-size: contain;
    top: 40px;
    left: calc(50% - 35px);
}
.box:hover.american:before{
    background-image: url(../image/american-white.png);
}
.football::before {
    position: absolute;
    content: "";
    background-image: url(../image/football.png);
    width: 75px;
    height: 75px;
    background-repeat: no-repeat;
    background-size: contain;
    top: 40px;
    left: calc(50% - 35px);
}
.box:hover.football:before{
    background-image: url(../image/soccer-white.png);
}
.cricket::before {
    position: absolute;
    content: "";
    background-image: url(../image/cricket.png);
    width: 75px;
    height: 75px;
    background-repeat: no-repeat;
    background-size: contain;
    top: 40px;
    left: calc(50% - 35px);
}
.box:hover.cricket:before{
    background-image: url(../image/cricket-white.png);
}
.what-is-first,
.what-is-second {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.what-is-second {
    padding-top: 80px;
    padding-bottom: 70px;
    border-bottom: 1px solid #1F3043;

}

.what-is .cta-btn span, .what-is .cta-btn a {
    text-transform: uppercase;
    padding: 19px 65px;
    cursor: pointer;
}

.what-is .cta-btn:hover span::before,
.faq-wrapper-content .cta-btn:hover span::before {
    position: absolute;
    content: "Coming soon!";
    width: 100%;
    height: 100%;
    background: #98263c;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -75px;
    left: 0;
}

.what-is .cta-btn:hover span::after,
.faq-wrapper-content .cta-btn:hover span::after  {
    border-color: #98263c transparent transparent;
    border-style: solid;
    border-width: 7px 10px 0;
    content: "";
    position: absolute;
    height: 0;
    left: calc(50% - 5px);
    top: -18px;
    transition: all .15s;
    width: 0;
    z-index: 0;
}

.what-is-content h2 {
    color: #fff;
}

.what-is-content .cta-btn{
    margin-top: 25px;
}

.what-is-img {
    background-image: url(../image/fantasy-league.png);
    background-repeat: no-repeat;
    background-position: 51px 193px;
    position: relative;
}

.what-is-img::before {
    position: absolute;
    content: "";
    background-image: url(../image/hero-lines.png);
    width: 1000px;
    background-repeat: no-repeat;
    background-size: contain;
    height: 336px;
    left: 150px;
    z-index: -1;
    top: 110px;
}

.what-is-img::after {
    position: absolute;
    content: "";
    width: 773px;
    height: 630px;
    background-color: #0176FE;
    opacity: 0.06;
    filter: blur(150px);
}

.second-img {
    background-image: url(../image/blockchain.png);
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-size: contain;
}

.second-img::before {
    position: absolute;
    content: "";
width: 100%;
height: 185px;
background: #0077FF;
opacity: 0.2;
filter: blur(150px);
top: calc(50% - 89px);
z-index: -1;
}

.how,
.the-best,
.how-to,
.about-us {
    text-align: center;
}

.how-to {
    position: relative;
    z-index: 5;
}

.thead p {
    text-transform: uppercase;
    font-weight: 400;
font-size: 15px;
line-height: 40px;
color: #656B71;
}

.the-best ul li {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 15px;
}

.association {
    padding-left: 150px;
}

.the-best ul {
    padding-left: 370px;
}

.the-best ul li div {
    /* background: #151F2A; */
    border-radius: 60px;
    padding: 9px;
}

.the-best-table {
    background-image: url(../image/the-best-table.png);
    background-repeat: no-repeat;
    background-position: right;
    margin-top: 100px;
    margin-bottom: 170px;
}

.the-best-table ul {
    position: relative;
}

.the-best-table ul::before {
    position: absolute;
    width: 977px;
    height: 944px;
    content: "";
    background: #0077FF;
    opacity: 0.1;
    filter: blur(300px);
    left: -319px;
    top: -124px;
}

.the-best-table ul::after {
    background-image: url(../image/rugby-player.webp);
    content: "";
    position: absolute;
    width: 360px;
    height: 480px;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    top: -100px;
}

.players,
.second-col {
    width: 40%;
}

.players p {
    font-weight: 700;
font-size: 19px;
line-height: 35px;
text-align: center;
color: #F43D58;
}

.association p {
    font-weight: 700;
font-size: 19px;
line-height: 35px;
}


.text-center h2{
    color: #000;
    font-weight: 300;
  }

  .faq-wrapper{
        position: relative;
        z-index: 10;
        padding: 0 50px 36px;
        background-color: #0C1118;
  }
  .faq-section-wrapper{
    padding-bottom: 90px;
  }

  .faq-title {
    align-items: center;
    display: flex;
    justify-content: space-between
  }
  
  .faq-title h3 {
    color: #000;
    font-size: 28px;
    font-weight: 400;
    height: 98px;
    line-height: 98px;
    pointer-events: none;
    margin-bottom: 0 !important;
    margin-top: 0;
    padding: 0;
  }
  .faq-btn {
    align-items: center;
    border: 0;
    border-radius: 25px;
    display: flex;
    font-size: 100px;
    font-weight: 200;
    height: 49px;
    justify-content: center;
    width: 49px;
  }
  
  
  
  .faq-btn:hover,.faq-title:hover {
    cursor: pointer
  }
  
  .faq {
    
    transition: background-color .2s linear,font-weight .2s linear,border-radius .2s linear;
    position: relative;
    padding-bottom: 0;
    border-top: 2px solid #171F29;
  }
  .faq-background-outer{
    border: 1px solid #3B495F;
    position: absolute;
    border-radius: 40px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .faq-content{
    z-index: 2;
  }
  
  .faq .faq-body {
    color: #3B495F;
    font-size: 17px;
    line-height: 24px;
    padding-bottom: 44px;
    padding-top: 0px;
    width: 95%;
  }
  .faq.collapsed .faq-body {
    display: none
  }
  .faq-body a,
  .about-us a{
    color: #F43D58;
  }
  


  .faq.expanded {
      padding-top: 0px;
  }

  .faq.expanded h3 {
      font-weight: 700;
  }
  
  .faq-btn span {
    pointer-events: none
  }
  
  .faq.collapsed .faq-btn span {
    color: #427CEC
  }
  
  .faq.expanded .faq-btn span {
    color: #427CEC
  }
  
  .faq.collapsed .faq-btn span.plus,.faq.expanded .faq-btn span.minus {
    display: block;
  }
  .faq.collapsed .faq-btn span.minus,.faq.expanded .faq-btn span.plus {
    display: none
  }

  .faq.expanded .faq-title h3 {
    font-weight: 700
  }
  .text-center {
    text-align: center;
    color: #000;
  }
  .text-center p{
    padding-bottom: 50px;
    color: #000;
  }
  .faq-title h3{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #A4ACB5;
    display: flex;
    align-items: center;
  }
  .faq-body p{
    text-align: left;
    color: #A4ACB5;
  } 
  
.plus::before {
    position: absolute;
    content: "";
    background-image: url(../image/plus.svg);
    width: 50px;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    top: 25px;
    right: 0;
    border-radius: 0px 20px;
}

.minus::before {
    position: absolute;
    content: "";
    background-image: url(../image/minus.svg);
    width: 50px;
    transition: all 0.2s;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    top: 25px;
    right: 0;
    border-radius: 0px 20px;
}

.faq:first-of-type {
    margin-top: 25px;
}

.faq-wrapper-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px 0;
    margin-top: 80px;
}

.faq-section h2 {
    padding-top: 0;
    padding-bottom: 0;
}

.faq-wrapper-content .cta-btn {
    text-transform: uppercase;
}

.faq-wrapper-content .cta-btn a {
    padding: 19px 65px;
}

.faq-cta-section {
    text-align: center;
}

.about-us {
    position: relative;
    z-index: 2;
    padding-bottom: 120px;
}

.about-us::before {
    position: absolute;
    content: "";
    background-image: url(../image/about-us-lines.png);
    width: 1500px;
    height: 483px;
    left: 111px;
    top: 51px;
    z-index: -1;
    background-repeat: no-repeat;
}

footer {
    background-color: #080C11;
    position: relative;
    z-index: 10;
    padding: 15px 0;
    
}

footer .container {
    display: flex;
    align-items: center;
    justify-content: center;
}

footer .logo-wrapper {
    display: none;
}

.copyright {
    color: grey;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

@media only screen and (max-width: 1200px) {
    .nav ul {
        gap: 10px;
    }

    .hero-img::before {
        right: -240px;
    }

    .types {
        width: 80%;
    }

    .what-is-img {
        background-position: 0 256px;
        background-size: contain;
    }
    
    .the-best ul {
        padding-left: 120px;
    } 
    .the-best-table ul::after {
        left: -250px;
    }
    .cta-btn span {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 1000px) {
    body {
        text-align: center;
    }
    .hero-img::before {
        content: none;
    }

    .hero-img::after {
        left: calc(50% - 365px);
    }
    .types {
        width: 100%;
    }
    .what-is-first, .what-is-second {
        grid-template-columns: 1fr;
    }

    .mobile-img {
        background-image: url(../image/fantasy-league.png);
        width: 100%;
        height: 250px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .second-img {
        height: 250px;
    }

    .second-img::before {
        left: 0;
    }

    .what-is-img::before {
        height: 200px;
        top: -530px;
    }

    .what-is-img::after {
        width: 537px;
        height: 412px;
        top: -650px;
    }

    .the-best ul {
        padding-left: 0;
        padding-top: 435px;
    } 
    .the-best-table {
        background-image: none;
        margin-bottom: 100px;
    }

    .the-best-table ul::after {
        left: calc(50% - 182px);
    }
    .the-best-table ul::before {
        left: calc(50% - 419px);
        height: 567px;
    }

    .the-best ul li {
        position: relative;
        z-index: 10;
        flex-direction: column;
        align-items: center;
    }

    .players, .second-col {
        width: 100%;
        background: #151F2A;
        padding: 15px !important;
    }

    .faq-cta-section {
        display: none;
    }

    .faq-wrapper-content {
        padding: 60px 0;
    }



    .thead {
        display: none;
    }
    
}

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 20px;
        line-height: 25px;
    }

    h1 span {
        font-size: 33px;
        line-height: 41px;
    }

    h2 {
        font-size: 30px;
    line-height: 38px;
    padding-top: 40px;
    }

    p {
        font-size: 18px;
        line-height: 30px;
        font-weight: 300;
    }

    .container,
    .site-header .container {
        padding: 0 22px !important;
    }


    .hero-img::after {
        width: 399px;
        height: 256px;
        bottom: 0;
        left: calc(50% - 205px);
    }

    .hero-img {
        height: 330px;
    }

    .types {
        flex-direction: column;
        gap: 15px;
    }

    .description {
        padding: 46px 0;
    }

    .mobile-img {
        height: 180px;
    }

    .second-img {
        height: 200px;
    }

    .what-is-second-content {
        padding-top: 20px;
    }

    .faq-body p {
        text-align: center;
    }

    .about-us {
        padding-bottom: 60px;
    }

    .faq-wrapper {
        position: relative;
        z-index: 2;
        padding: 0 0px 36px;
    }
    
    .faq-wrapper::before {
        position: absolute;
        content: "";
        width: 200%;
        height: 100%;
        background-color: #0C1118;
        left: calc(50% - 258px);
    }

    .faq-section {
        position: relative;
    }

}


/* Header */

.scroll-to-link > *{
    pointer-events: none;
  }
  
  @media only screen and (max-width: 1067px) {
    .site-header{
        padding: 0;
    }
    .site-header .container{
        padding: 0;
    }
    .site-header .site-logo .main-logo{
      width: 150px;
    }
    .site-header .site-logo::after{
      content: none;
    }
    .site-header .intro-primary.grid{
      position: unset;
    }
    .site-header .intro-entry{
        grid-template-columns: 1fr;
        padding: 0px 20px 40px 20px;
    }
    .main-image{
        background-size: contain;
        background-position: center;
        width: 100%;
        height: 150px;
        background-repeat: no-repeat;
        grid-row: 1;
    }
    .site-header .main-image::before{
        width: 60%;
    }
    .site-header .main-image::after{
       content: none;
    }
    .site-header .intro-entry p{
        width: 100%;
    }
    .double-arrow{
      margin-bottom: 40px;
    }
  }
  /* Navigation */
  


    @media (min-width: 1300px){
        a.button {
            min-width: 170px;
        }
    }
    @media only screen and (max-width: 1067px){


        .site-header .cta-btn {
            display: none;
        }
        .site-header .site-nav{
            z-index: auto;
            position: relative;
        }
        .nav-visible .site-logo{
            padding-top: 10px;
        }
        .site-nav .icon{
            border-top: 3.5px solid var(--button-primary-color);
            height: 20px;
            width: 30px;
            box-sizing: border-box;
            position: absolute;
            z-index: 30;
            right: 0px;
            top: -15px;
            cursor: pointer;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -khtml-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            transition: all 0.3s ease-in;
        }
        .site-nav .icon:before {
            content: "";
            display: block;
            position: absolute;
            height: 3.5px;
            width: 30px;
            right: 0;
            background: var(--button-primary-color);
            top: 5px;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -khtml-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            transition: all 0.3s ease-in;
        }
        .site-nav .icon:after {
            content: "";
            display: block;
            position: absolute;
            height: 3.5px;
            width: 30px;
            right: 0;
            background: var(--button-primary-color);
            bottom: -1px;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -khtml-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            transition: all 0.3s ease-in;
        }
        .nav-visible .site-nav .icon{
            border: transparent;
            top: 30px;
            right: 20px;
        }
        .nav-visible .site-nav .icon:before {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -khtml-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            width: 33px;
            right: -2px;
        }
        .nav-visible .site-nav .icon:after {
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -khtml-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
            width: 33px;
            right: -1px;
            top: 5px;
        }
        .nav-visible .site-nav{
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            overflow-x: hidden;
            overflow-y: auto;
            text-align: left;
            z-index: 10;
            background: #0F161E;
        }

        .nav-visible .nav::before {
            content: none;
        }
        .site-header.nav-visible .container{
            display: flow-root;
        }
        .site-nav>ul{
            opacity: 0;
            visibility: hidden;
            display: none;
        }
        .nav-visible .site-nav>ul {
            display: grid;
            justify-content: center;
            align-items: center;
            opacity: 1;
            visibility: visible;
            position: fixed;
            width: 100%;
            height: 70%;
            top: 70px;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-x: hidden;
            overflow-y: auto;
            text-align: center;
            margin: 0 auto;
        }
        .nav-visible .site-nav>ul>li{
          margin-bottom: 0px; 
          margin-right: 0px;
          padding: 0 30px;
        }

        .nav ul li a:hover::before {
            opacity: 0;
        }

        .btn-group li {
            position: relative;
        }
        .nav-visible .site-nav>ul>li:hover::before{
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background-color: #F43D58;
            opacity: 0.15;
            border-radius: 22px;
            color: #F43D58 !important;
            top: 0;
            left: 0;
        }
        .nav-visible .site-nav>ul>li:hover a{
          color: #F43D58 !important;
        }
        .nav-visible .site-nav>ul>li:nth-of-type(2) {
          margin-left: 0px;
      }
        .nav-visible .site-nav>ul>li:before{
          content: none;
        }
        .nav-visible .site-nav>ul>li a{
            font-size: 23px;
            line-height: 30px;
            text-align: center;
            color: #FFFFFF;
            font-weight: 700;
        }
  
        .nav-visible  .btn-group{
            box-shadow: none;
        }
        .nav-visible .site-nav>ul>li a.btn ,  .nav-visible .btn-group li:not(:last-child):not(.dropdown-toggle) .btn, .nav-visible .btn-group li:not(:first-child) .btn{
            border: none;
            background-color: transparent;
        }
  }
  @media only screen and (max-width: 1024px){
    .cta-btn {
        width: 100%;
    }
  }
